"use client"

import { GetPageQuery } from "apollo"
import { Hero, Quote } from "ui"
import { BlockRenderer, findProperty } from "utils"
import CorpBrands from "./Brands"
import BrandsValue from "./BrandsValue"
import PositionedSearch from "./PositionedSearch"

type Props = {
  data: GetPageQuery
}

const components = {
  sliderBlock: Hero,
  quoteBlock: Quote,
  imagnextTextSlider: BrandsValue,
  linksLists: CorpBrands,
}


function Homepage({ data }: Props) {
  return (
    <>
      {findProperty(data?.page, "blocks")?.blocks?.map(
        // TO DO : HOW TO FIND BLOCK TYPE IN GENERATED GRAPHQL TYPES

        (block: any, index: number) => {
          let addProp = {}
          if (block.blockType === "linksLists") {
            addProp = {
              mainTitle: "מותגי הקבוצה",
            }
          } else if (block.blockType === "sliderBlock") {
            addProp = { corp: true }
          } else if (block.blockType === "quoteBlock") {
            addProp = { titleComponent: "h1" }
          }
          return (
            <BlockRenderer
              index={index}
              key={index}
              blockType={block.blockType}
              blockData={block}
              components={components}
              props={addProp}
            >
              {block.blockType === "sliderBlock" && (
                <PositionedSearch
                  title="פז בדרך שלך"
                  subtitle="מזמינים אתכם למצוא את כל המתחמים, החנויות, והשירותים שלנו"
                  placeholder="הזן מיקום"
                  desktopUrl={"/images/search-background-desktop.png"}
                  mobileUrl={"/images/search-background-mobile.png"}
                  searchIcon={true}
                />
              )}
            </BlockRenderer>
          )
        },
      )}
    </>
  )
}

export default Homepage
