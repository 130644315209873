"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

import "swiper/css"
import { Navigation } from "swiper/modules"

import { Swiper, SwiperSlide } from "swiper/react"

import {
  Desktop,
  LeftArrow,
  LeftMobileArrow,
  Mobile,
  NewText,
  ReadMoreIcon,
  RightArrow,
  RightMobileArrow,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty } from "utils"
import { getNonUmbracoURL, httpsImage } from "utils/utils"
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledImageContainer = styled.div<{
  withRightImage: boolean
}>`
  width: 100%;
  height: 375px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 640px;
    order: ${(props) => (props.withRightImage ? "0" : "1")};
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 900px;
  }
`

const StyledTextContainer = styled.div<{
  withRightImage: boolean
  $justifyContent: string
  last?: boolean
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) => props.theme.breakpoints.down("md")} {
    border-bottom: 1px solid #bad2d44d;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 111px;
    padding-left: ${(props) => (props.withRightImage ? "70" : "110")}px;
    padding-right: ${(props) => (props.withRightImage ? "110" : "70")}px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding-top: 164px;
    padding-left: ${(props) => (props.withRightImage ? "120" : "220")}px;
    padding-right: ${(props) => (props.withRightImage ? "220" : "120")}px;
  }
`

const StyledCTA = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${() => sharedColors.blue};
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  direction: ltr; /* Left-to-Right */
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 25.2px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
  }
`
const StyledSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 32px 36px 32px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
  }
`
const StyledArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  z-index: 9;
  right: 0px;
  top: 14px;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    top: 0px;
  }
`

const StyledArrowTextContainer = styled.div`
  width: 22px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 48px;
  }
`

const StyledArrowContainer = styled(Button)`
  padding: 0px;
  min-width: auto;
`

const StyledSlideIconContainer = styled.div`
  position: relative;
  height: 50px;

  margin-bottom: 12px;
  img {
    width: auto !important;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 65px;
    margin-bottom: 21px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 76px;
    margin-bottom: 32px;
  }
`

const StyledTagsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding-bottom: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 10px;
    padding-bottom: 32px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding-top: 16px;
    padding-bottom: 21px;
  }
`

const StyledTitle = styled(NewText)`
  padding-bottom: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 10px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding-bottom: 16px;
  }
`

const NewTextDescr = styled(NewText)`
  padding-bottom: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 16px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding-bottom: 24px;
  }
`

const StyledTag = styled(Link)`
  border: 1px solid ${sharedColors.blue};
  border-radius: 20px;
  padding: 8px 10px;
  text-decoration: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    border: 2px solid ${sharedColors.blue};
    padding: 6px 14px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 8px 20px;
  }
`

type Props = {
  data: any
  withCover?: boolean
  keyForComponent: number
  justifyContent?: string
}

export const BrandsValue: React.FC<Props> = ({
  data,
  withCover,
  keyForComponent,
  justifyContent = "space-between",
}) => {
  const innerSliderBlocks = extractProperty(data, "innerSliderImageNextToText")
  const withRightImage = extractProperty(data, "flipSide")

  const items = innerSliderBlocks.map((block: any) => {
    const image = extractProperty(block, "imgText")
    const logo = extractProperty(block, "logo")
    const slidTitle = extractProperty(block, "slidTitle")
    const slidContent = extractProperty(block, "slidContent")
    const slidfUrl = extractProperty(block, "slidfUrl")
    const slidLink = extractProperty(block, "slidLink")
    const imageAlt = extractProperty(block, "altTextForImage")
    return {
      bigImage: {
        url: image,
        alt: imageAlt,
      },
      icon: {
        url: logo,
        alt: slidfUrl?.[0]?.name?.replace(/^ל/, ""),
      },
      title: slidTitle,
      subtitle: slidContent,
      cta: {
        url: slidfUrl?.[0]?.url,
        text: slidfUrl?.[0]?.name,
        target: slidfUrl?.[0]?.target,
      },
      tags: slidLink,
    }
  })
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Swiper
      navigation={{
        nextEl: `.arrow-right-${keyForComponent}-1`,
        prevEl: `.arrow-left-${keyForComponent}-1`,
      }}
      style={{ padding: 0 }}
      key={keyForComponent + "slider"}
      modules={[Navigation]}
      slidesPerView={1}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      onSwiper={() => { }}
    >
      {items.map((item: any, index: number) => {
        return (
          <SwiperSlide key={index.toString()}>
            <StyledGrid>
              <StyledImageContainer withRightImage={!withRightImage}>
                <Image
                  style={{ objectFit: withCover ? "cover" : "contain" }}
                  src={httpsImage(item?.bigImage?.url)}
                  alt={item?.bigImage?.alt}
                  fill
                  quality={85}
                />
              </StyledImageContainer>
              <StyledTextContainer
                withRightImage={!withRightImage}
                $justifyContent={justifyContent}
              >
                <StyledSlideContainer>
                  {item.icon.url && (
                    <StyledSlideIconContainer>
                      <Image
                        // style={{ objectFit: "contain" }}
                        src={httpsImage(item.icon.url)}
                        alt={item.icon.alt}
                        fill
                      />
                    </StyledSlideIconContainer>
                  )}
                  <StyledTitle
                    mobileFontSize={28}
                    mobileLineHeight="35.84px"
                    largeDesktopFontSize={48}
                    largeDesktopLineHeight="67.2px"
                    desktopFontSize={32}
                    desktopLineHeight="44.8px"
                    component="h3"
                    desktopFontWeight="500"
                    color={sharedColors.black}
                    dangerouslySetInnerHTML={{ __html: item.title }} // For rich text rendering
                  />
                  <NewTextDescr
                    mobileFontSize={18}
                    mobileLineHeight={"24px"}
                    largeDesktopFontSize={24}
                    largeDesktopLineHeight="33.6px"
                    desktopFontSize={16}
                    desktopLineHeight={"22.4px"}
                    component="p"
                    desktopFontWeight="400"
                    color={sharedColors.lighterBlack}
                    dangerouslySetInnerHTML={{ __html: item.subtitle }} // For rich text rendering
                  />
                  {item.tags?.length ? (
                    <StyledTagsContainer>
                      {item.tags.map((tag: any, i: number) => {
                        const link = extractProperty(tag, "link")
                        const linkTitle = extractProperty(tag, "linkTitle")

                        return (
                          <StyledTag
                            href={getNonUmbracoURL(link?.[0]?.url)}
                            key={i.toString()}
                          >
                            <NewText
                              component="p"
                              largeDesktopFontSize={16}
                              largeDesktopLineHeight="24px"
                              desktopFontSize={12}
                              desktopLineHeight={"16px"}
                              mobileLineHeight="24px"
                              mobileFontSize={14}
                              desktopFontWeight="400"
                              color={sharedColors.blue}
                            >
                              {linkTitle}
                            </NewText>
                          </StyledTag>
                        )
                      })}
                    </StyledTagsContainer>
                  ) : null}
                  {!!item.cta.url && (
                    <StyledCTA
                      target={item.cta.target}
                      href={getNonUmbracoURL(item.cta.url)}
                    >
                      {item.cta.text}

                      <ReadMoreIcon />
                    </StyledCTA>
                  )}
                </StyledSlideContainer>
                {items.length > 1 && (
                  <StyledArrowsContainer>
                    <StyledArrowContainer
                      className={`arrow-left-${keyForComponent}-1 arrow`}
                    >
                      <Desktop>
                        <RightArrow />
                      </Desktop>
                      <Mobile>
                        <RightMobileArrow />
                      </Mobile>
                    </StyledArrowContainer>
                    <StyledArrowTextContainer>
                      <Desktop>
                        <NewText
                          desktopFontSize={16}
                          component="p"
                          desktopLineHeight="150%"
                          desktopFontWeight="400"
                          color={sharedColors.darkGray}
                        >
                          {activeIndex + 1}/{items.length}
                        </NewText>
                      </Desktop>
                      <Mobile>
                        <NewText
                          desktopFontSize={14}
                          component="p"
                          desktopLineHeight="20px"
                          desktopFontWeight="400"
                          color={sharedColors.darkGray}
                        >
                          {activeIndex + 1}/{items.length}
                        </NewText>
                      </Mobile>
                    </StyledArrowTextContainer>
                    <StyledArrowContainer
                      className={`arrow-right-${keyForComponent}-1 arrow`}
                    >
                      <Desktop>
                        <LeftArrow />
                      </Desktop>
                      <Mobile>
                        <LeftMobileArrow />
                      </Mobile>
                    </StyledArrowContainer>
                  </StyledArrowsContainer>
                )}
              </StyledTextContainer>
            </StyledGrid>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default BrandsValue
