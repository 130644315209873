"use client"

import styled from "@emotion/styled"
import { useState } from "react"
import { trackSearchLocation } from "reusable/gtm"
import {
  Desktop,
  LinkComponent,
  NewText,
  ReadMoreIcon,
  SearchInput,
  useIsDesktop,
} from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledSearchContainer = styled.div<{
  $desktopUrl: string
  $mobileUrl: string
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 310px;
  height: 120px;
  display: flex;
  align-items: center;
  background-image: url(${(p) => p.$desktopUrl});
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    bottom: 0;
    right: 0;
    width: 420px;
    height: 180px;
    padding: 45px;
    background-image: url(${(p) => p.$desktopUrl});
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    bottom: 0;
    right: 0;
    width: 603px;
    height: 199px;
    padding: 65px;
    background-image: url(${(p) => p.$desktopUrl});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }
`

const StyledInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`
const StyledInput = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    transform: translate(10px, 10px);
  }
`
const StyledGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    flex-direction: row-reverse;
  }
  svg {
    margin-left: 10px;
  }
`

type Props = {
  title: string
  subtitle: string
  placeholder: string
  desktopUrl: string
  mobileUrl: string
  color?: string
  searchIcon?: boolean
  linkText?: string
}

export const PositionedSearch: React.FC<Props> = ({
  title,
  subtitle,
  placeholder,
  desktopUrl,
  mobileUrl,
  color = sharedColors.white,
  linkText,
  searchIcon,
}) => {
  const [value, setValue] = useState<string>("")
  const isDesktop = useIsDesktop()

  return (
    <StyledSearchContainer $desktopUrl={desktopUrl} $mobileUrl={mobileUrl}>
      <StyledInputContainer>
        <StyledGap>
          <StyledTextWrapper>
            <NewText
              component={"h2"}
              largeDesktopFontSize={28}
              largeDesktopLineHeight="35.84px"
              desktopFontSize={18}
              desktopLineHeight="23.04px"
              mobileFontSize={20}
              mobileLineHeight={"28px"}
              desktopFontWeight={500}
              color={color}
            >
              {title}
            </NewText>
            {linkText && (
              <LinkComponent
                text={"לכל המוצרים"}
                link={"/products"}
                mobileFontSize={14}
                fontSize={16}
                fontWeight={500}
                icon={<ReadMoreIcon />}
              />
            )}
          </StyledTextWrapper>
          <Desktop>
            <NewText
              component={"span"}
              largeDesktopFontSize={18}
              largeDesktopLineHeight="24px"
              desktopFontSize={12}
              desktopLineHeight="16px"
              fontWeight="400"
              color={color}
            >
              {subtitle}
            </NewText>
          </Desktop>
        </StyledGap>
        <StyledInput>
          <SearchInput
            height={!isDesktop ? "40px" : undefined}
            placeholder={placeholder}
            searchIcon={searchIcon || false}
            radius="200px"
            value={value}
            setValue={setValue}
            isCorporate
            onSubmitCallback={() => trackSearchLocation(value)}
          />
        </StyledInput>
      </StyledInputContainer>
    </StyledSearchContainer>
  )
}

export default PositionedSearch
